import React, { ReactNode } from "react"
import {
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import {
  HOW_TO_SCAN_ARTICLE_LINK,
  MANUALLY_SEND_QR_CODES_ARTICLE_LINK,
  QR_CODES_FOR_CONTACT_EXCHANGE_ARTICLE_LINK,
} from "utilities/externalLinks"
import { AndroidAppURL, iOSAppURL } from "utilities/routes"
import AppDownloadLinks from "components/AppDownloadLinks"
import RoundedList from "../RoundedList"
import LinkListItem from "../LinkListItem"

type DocumentationLink = {
  id: string
  title: string
  description: string | ReactNode
  href: string
}

const DOCUMENTATION_LINKS: DocumentationLink[] = [
  {
    id: "manually-send-qr-codes",
    title: "Manually Send QR Codes to Guests",
    description: "Learn how to send QR codes to one or multiple guests using manual delivery methods.",
    href: MANUALLY_SEND_QR_CODES_ARTICLE_LINK,
  },
  {
    id: "qr-codes-for-contact-exchange",
    title: "QR Codes for Contact Exchange",
    description: "Diobox badges can embed vCard data, enabling guests to exchange contact information.",
    href: QR_CODES_FOR_CONTACT_EXCHANGE_ARTICLE_LINK,
  },
  {
    id: "scanning-qr-codes",
    title: "Scanning QR Codes",
    description: (
      <VStack spacing={6}>
        <Text>Use the Diobox iOS/Android apps to scan and track check-ins with features for
          custom scanning behavior,restrictions, and duplicate handling.
        </Text>
        <AppDownloadLinks
          iOSAppURL={iOSAppURL}
          AndroidAppURL={AndroidAppURL}
        />
      </VStack>
    ),
    href: HOW_TO_SCAN_ARTICLE_LINK,
  },
]

const AdditionalDocumentation = () => (
  <Stack spacing={6}>
    <SectionHeader>Additional Documentation</SectionHeader>
    <RoundedList>
      {DOCUMENTATION_LINKS.map((link: DocumentationLink) => (
        <LinkListItem
          key={link.id}
          title={link.title}
          description={link.description}
          href={link.href}
          isExternal
        />
      ))}
    </RoundedList>
  </Stack>
)

export default AdditionalDocumentation
