import React from "react"
import { Box, Stack } from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import RadioButtons, { RadioOptionLabel } from "components/ReactHookForm/RadioButtons"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import { Boolean } from "utilities/enums"
import useCurrentPlanType from "utilities/useCurrentPlanType"

type Props = {
  expiringQRCodes: boolean
  updateEvent: (params: {}) => Promise<void>;
}

const QRCodeSecurity = ({ expiringQRCodes, updateEvent }: Props) => {
  const { corporateFeaturesEnabled: isCorporate } = useCurrentPlanType()

  const onSubmit = ({ qrCodeSecurity }) => updateEvent({
    expiringQrCodes: qrCodeSecurity === Boolean.True,
  })

  return (
    <Stack spacing={6}>
      <SectionHeader>QR Code Security</SectionHeader>
      <ContainedForm
        values={{
          qrCodeSecurity: expiringQRCodes ? Boolean.True : Boolean.False,
        }}
        onSubmit={onSubmit}
        name="QR Code Security"
      >
        {({ control }) => (
          <Box pt={4}>
            <QRCodeSecurityFormFields control={control} isCorporate={isCorporate} />
            {!isCorporate && (
              <UpgradeAlert text="This feature requires a Corporate Subscription." />
            )}
          </Box>
        )}
      </ContainedForm>
    </Stack>
  )
}

export const QRCodeSecurityFormFields = ({ control, isCorporate }) => (
  <RadioButtons
    name="qrCodeSecurity"
    control={control}
    spacing={6}
    options={[
      {
        label: (
          <RadioOptionLabel
            title="Standard QR Codes"
            description="Guests receive standard QR codes after successful registration or ticket payment. These codes streamline the check-in process."
          />
        ),
        value: Boolean.False,
      },
      {
        label: (
          <RadioOptionLabel
            disabled={!isCorporate}
            title="Temporal QR Codes"
            description="Temporal QR codes are Diobox’s proprietary, time-based codes  that prevent guests from using screenshots or sharing QR codes. Each code can only be used once, ensuring secure verification."
          />
        ),
        value: Boolean.True,
        disabled: !isCorporate,
      },
    ]}
  />
)

export default QRCodeSecurity
