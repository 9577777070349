import React from "react"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import {
  Box, Flex, Image, Stack, Text,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import { iOSAppURL } from "utilities/routes"
import download from "assets/images/download-from-app-store.png"
import badgePrintingPreview from "assets/images/qr-codes-ios-app-preview.png"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { DIOBOX_APP_LINK, HOW_TO_SCAN_ARTICLE_LINK, MANUALLY_SEND_QR_CODES_ARTICLE_LINK } from "utilities/externalLinks"

const QRCodesFeatureDisabled = () => (
  <>
    <SectionHeader>Distributing QR Codes</SectionHeader>
    <Box mt={6} mb={10} borderRadius="xl" bgColor="white" borderWidth={1} p={6}>
      <Box>
        <Text mb={4}>
          By default, the QR codes are automatically generated and sent to the guest when they
          order a ticket or confirm an RSVP, so there is no configuration or setup. All the QR
          codes are included in the emails with a link for the guest to print them. However, you
          can still manually generate and distribute QR codes for them.
        </Text>
        <Text>
          To learn more, see:{" "}
          <Link isExternal href={MANUALLY_SEND_QR_CODES_ARTICLE_LINK}>
            Manually Send QR Codes to Guests
          </Link>
        </Text>
      </Box>
    </Box>
    <SectionHeader>Scanning QR Codes</SectionHeader>
    <Stack mt={6} spacing={10} align="center">
      <Box borderRadius="xl" bgColor="white" borderWidth={1} p={6} overflow="hidden">
        <Flex mb={8}>
          <Box>
            <Text mb={4}>
              Use the built-in QR scanner on the{" "}
              <Link isExternal href={DIOBOX_APP_LINK}>Diobox iOS App.</Link>
              to track guest check-ins. The QR scanner can perform search and check-in
              simultaneously, enabling your team to process guest arrivals quickly and effortlessly.
              The QR Scanner comes with many advanced features to change the scanning behavior,
              enforce check-in restrictions, manage duplicate scans, and more.
            </Text>
            <Text mb={4}>
              To learn more, see:{" "}
              <Link isExternal href={HOW_TO_SCAN_ARTICLE_LINK}>
                How to Scan Guest QR Codes at Your Event
              </Link>
            </Text>
          </Box>
        </Flex>
        <Image src={badgePrintingPreview} display="block" marginLeft="auto" marginRight={-6} marginBottom={-6} w="75%" />
      </Box>
      <Link w={130} display="block" href={iOSAppURL} isExternal>
        <Image src={download} />
      </Link>
      <Box w="full"><UpgradeAlert text="Feature requires a Professional Subscription or above." /></Box>
    </Stack>
  </>

)

export default QRCodesFeatureDisabled
