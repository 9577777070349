import React, { ReactNode } from "react"
import { Link as RouterLink } from "react-router-dom"
import Icon from "components/elements/Icon"
import Link from "components/elements/Link"
import { Box } from "@chakra-ui/react"
import { RoundedListItem } from "./RoundedList"

interface LinkListItemProps {
  title: string | ReactNode
  description?: string | ReactNode
  to?: string
  href?: string
  isExternal?: boolean
}

const LinkListItem = ({
  title,
  description,
  to,
  href,
  isExternal = false,
}: LinkListItemProps) => {
  const icon = (
    <Icon
      icon="next-arrow"
      size={3}
      ml={5}
      color="gray.500"
    />
  )

  const content = (
    <Box _hover={{ backgroundColor: "gray.50" }} width="100%">
      <RoundedListItem
        text1={title}
        text2={description}
        action={icon}
      />
    </Box>
  )

  if (isExternal && href) {
    return (
      <Link href={href} color="gray.800" textDecoration="none" isExternal>
        {content}
      </Link>
    )
  }

  return (
    <RouterLink to={to || "#"} style={{ color: "inherit", textDecoration: "none" }}>
      {content}
    </RouterLink>
  )
}

export default LinkListItem
