export const DIOBOX_FORMS_ARTICLE_LINK = "https://help.d.io/en/articles/8405358-overview-of-diobox-forms"
export const HELP_ARTICLE_HREF = "https://help.d.io/en/articles/3225045-importing-guests-from-a-csv-file"
export const BADGE_PRINTING_ARTICLE_URL = "https://help.d.io/en/collections/3027242-badge-printing"
export const IMPORTING_GUEST_CSV_ARTICLE_LINK = "http://help.diobox.com/importing-guests-csv-file/"
export const IMPORTING_GUEST_CSV_TEMPLATE_URL = "https://data.diobox.com/app/import-template.csv"
export const HOW_TO_SCAN_ARTICLE_LINK = "https://help.d.io/en/articles/5305750-how-to-scan-guest-qr-codes-at-your-event"
export const STRIPE_DASHBOARD = "https://dashboard.stripe.com/"
export const DIOBOX_UPDATES_LINK = "https://updates.d.io/"
export const DIOBOX_HELP_LINK = "http://help.diobox.com"
export const DIOBOX_PRICING_URL = "https://home.d.io/pricing/"
export const PLUS_ONE_ARTICLE_LINK = "https://help.d.io/en/articles/5115636-plus-ones"
export const SEND_CC_MULTIPLE_ADDRESSES_ARTICLE_LINK = "https://help.d.io/en/articles/6501825-send-ccs-to-multiple-email-addresses-listed-on-a-guest-profile"
export const MANUALLY_SEND_QR_CODES_ARTICLE_LINK = "https://help.d.io/en/articles/5305754-manually-send-qr-codes-to-guests"
export const QR_CODES_FOR_CONTACT_EXCHANGE_ARTICLE_LINK = "https://help.d.io/en/articles/5305741-qr-codes-for-contact-exchange"
export const DIOBOX_APP_LINK = "https://apps.apple.com/us/app/diobox-event-guest-list-tables/id889676302"
export const DIOBOX_LEGAL_LINK = "http://data.diobox.com/legal/terms.html"
export const DIOBOX_PRIVACY_LINK = "http://data.diobox.com/legal/privacy.html"
export const DIOBOX_CUSTOM_EMAIL_DOMAIN_VERIFICATION_ARTICLE_LINK = "https://help.d.io/en/articles/8901566-custom-email-domains"
export const GUEST_PORTAL_HELP_ARTICLE_LINK = "https://help.d.io/en/collections/9169425-registration-forms"
export const EMAIL_BUILDER_EMAIL_INVITATION_ARTICLE_LINK = "https://help.d.io/en/collections/1883583-emails-invitations"
export const TEXT_MESSAGING_ARTICLE_LINK = "https://help.d.io/en/articles/10567948-about-diobox-text-message-sending"
export const TEXT_MESSAGING_TRACKING_ARTICLE_LINK = "https://help.d.io/en/articles/10023237-text-message-tracking"
export const LEAD_RETRIEVAL_APP_APPSTORE_LINK = "https://help.d.io/en/articles/10312711-download-lead-retrieval-apps"
export const LEAD_RETRIEVAL_APP_PLAYSTORE_LINK = "https://play.google.com/store/apps/details?id=com.diobox.leadretrieval"
export const LEAD_RETRIEVAL_HELP_ARTICLE_LINK = "https://help.d.io/en/collections/11161409-lead-retrieval"
export const getGuestPortalUrl = (externalId: string) => `${import.meta.env.VITE_WEBSITE_HOST}/e/${externalId}`
export const embedUrl = `${import.meta.env.VITE_WEBSITE_HOST}/embed/v1`
