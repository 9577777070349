import React from "react"
import {
  Stack,
} from "@chakra-ui/react"
import QRCodeSecurity from "./QRCodeSecurity"
import RelatedSettings from "./RelatedSettings"
import AdditionalDocumentation from "./AdditionalDocumentation"
import QRCodesFeatureDisabled from "./QRCodesFeatureDisabled"

type Props = {
  enabled: boolean
  expiringQRCodes: boolean
  eventId: string
  updateEvent: (params: {}) => Promise<void>;
}

const QRCodes = ({
  enabled,
  expiringQRCodes,
  eventId,
  updateEvent,
}: Props) => {
  if (!enabled) {
    return (
      <QRCodesFeatureDisabled />
    )
  }

  return (
    <Stack spacing={16}>
      <QRCodeSecurity expiringQRCodes={expiringQRCodes} updateEvent={updateEvent} />
      <RelatedSettings eventId={eventId} />
      <AdditionalDocumentation />
    </Stack>
  )
}

export default QRCodes
