import React from "react"
import { Stack } from "@chakra-ui/react"
import { FormsSubpath } from "sharedTypes"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import { eventFormsPath } from "utilities/routes"
import RoundedList from "../RoundedList"
import LinkListItem from "../LinkListItem"

type Props = {
  eventId: string
}

const RelatedSettings = ({ eventId }: Props) => (
  <Stack spacing={6}>
    <SectionHeader>Related Settings</SectionHeader>
    <RoundedList>
      <LinkListItem
        title="Email Responders"
        description="Customize confirmation emails and control QR code visibility for guests who register."
        to={eventFormsPath(eventId, FormsSubpath.EmailResponders)}
      />
    </RoundedList>
  </Stack>
)

export default RelatedSettings
